@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.12.0/styles/atelier-cave-dark.min.css);
.App {
  padding: 0;
}

.content {
  display: flex;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
}

.card {
  width: 70%;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

